<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
      
      <v-container fluid>
        <v-layout wrap>
              <v-col cols="12" align="center" >
                <v-img src="img/helpCenter.jpeg" alt="Help Center" width="450" style="border-radius:10px;" >
                </v-img>
                <h1>qikPharma Customer Care</h1>
                <v-row class="mt-5">
                    <v-col cols="12"  md="10" offset-md="1">
                    <v-text-field
                        label="Describe your issue"
                        append-icon="mdi-magnify"
                        outlined
                        dense
                        color="#7CBF46"
                    ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-3">
                    <v-col cols="12"  md="10" offset-md="1">
                         <v-row>
                            <v-col cols="4"  md="2" offset-md="1" justify="center" align="center">
                                <v-icon
                                style="color:#7CBF46 !important; font-size:70px !important"
                                dark
                                >mdi-basket-fill
                                </v-icon>
                                <h6 class="mt-3">Place Order</h6>
                            </v-col>
                            <v-col cols="4"  md="2" justify="center" align="center">
                                <v-icon
                                style="color:#7CBF46 !important; font-size:70px !important"
                                dark
                                >mdi-truck-delivery-outline
                                </v-icon>
                                <h6 class="mt-3">Track Order</h6>
                            </v-col>
                            <v-col cols="4"  md="2" justify="center" align="center">
                                <v-icon
                                style="color:#7CBF46 !important; font-size:70px !important"
                                dark
                                >mdi-archive-cancel-outline
                                </v-icon>
                                <h6 class="mt-3">Cancel Order</h6>
                            </v-col>
                            <v-col cols="4"  md="2" justify="center" align="center">
                                <v-icon
                                style="color:#7CBF46 !important; font-size:70px !important"
                                dark
                                >mdi-cash-refund
                                </v-icon>
                                <h6 class="mt-3">Returns & Refunds</h6>
                            </v-col>
                             <v-col cols="4"  md="2" justify="center" align="center">
                                <v-icon
                                style="color:#7CBF46 !important; font-size:70px !important"
                                dark
                                >mdi-cash-sync
                                </v-icon>
                                <h6 class="mt-3">Payments</h6>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
              </v-col>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap class="mt-2 mb-5">
              <v-col cols="12" align="center" >
                 <h1>Our Headquarters</h1>
                 <p>Our global headquarters are in the UK. We also have a significant presence in the Ghana and Nigerian</p>
                 <v-layout>
                    <v-col cols="12" md="6" offset-md="3" align="left" >
                        <v-expansion-panels inset>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                <h5>United Kingdom</h5>
                                <template v-slot:actions>
                                    <v-icon color="teal">
                                     $expand
                                    </v-icon>
                                </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                   <p>Address: 
                                    <br/>
                                     Phone: <a href="tel:01616696279">0161-669-6279</a> 
                                    <br/>
                                    Email: <a href="mailto:qikPharmaUK@qikPharma.com">letstalk@qikpharma.com</a> </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                <h5>Ghana</h5>
                                <template v-slot:actions>
                                    <v-icon color="teal">
                                     $expand
                                    </v-icon>
                                </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p>Address: 
                                        <br/>
                                        Phone: <a href="tel:+233241917797">+233-24-191-7797</a> 
                                        <br/>
                                        Email: <a href="mailto:letstalkghana@qikpharma.com">letstalkghana@qikpharma.com</a> 
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                <h5>Nigeria</h5>
                                <template v-slot:actions>
                                    <v-icon color="teal">
                                     $expand
                                    </v-icon>
                                </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                 <p>Address: 
                                        <br/>
                                        Phone: <a href="tel:0806-215-9230">0806-215-9230</a> 
                                        <br/>
                                        Email: <a href="mailto:letstalknigeria@qikpharma.com">letstalknigeria@qikpharma.com</a> 
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                 </v-layout>
              </v-col>

        </v-layout>


      </v-container>

      <v-divider></v-divider>
    
      <FooterFeature></FooterFeature>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "About",


  data: () => ({
      slides: [
        {
          src: "slider/Slider-4.png",
          title: "qikPharam Customer Support",
          text: "We are here to help at anytime",
          btnText: "Live Chat",
          link: "#",
        },
      ],
       categoryItems: null,
  }),
  created() {
    this.getProductCat();
  },
  methods: {
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.5;
}

</style>
